@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bad+Script&family=Borel&family=Fjalla+One&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,700&family=Mooli&family=Passion+One:wght@900&family=Russo+One&family=Young+Serif&display=swap');
.serif{
  font-family: 'Young Serif', serif;
}
body{
 
}
.menu-kasterina-link{color:black;border-bottom:2px solid transparent}
.menu-kasterina-link:hover{border-bottom:2px solid black;color:black !important;}
.barlow{
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
.oswald {
  line-height: 50px;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  margin: 0;

  font-weight: 300;
  font-size: 59px;
 
  -webkit-text-decoration: none;
  text-decoration: none;
  font-style: normal;
  font-size: 72px;
  letter-spacing: -0.72px;
  text-align: center;
  color: #000000ff;
}

.paymentMethod:hover{
  transform: scale(0.9);
  transition: 300ms ease-in;

}
.notification-Top{
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;

}

.previous__link-pagination,.next__link-pagination{
  background-color: #2141ce;
  padding: 8px 15px;
  color: white;
	  font-family: SpaceMono,monospace,Courier New,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 600;
  font-size: '15px';

}
.container-pagination a{
  border-radius: 5px;
  
  cursor: pointer;
  margin: 8px;
  padding: 10px;
  color: white;
  overflow: hidden;
  background-color: #2141ce;
}
.active-pagination a{
  background-color: white;
  border: 2px solid black;
  color: black;
}
.courseGrid{
  display: grid;
  grid-template-columns: 21% 21% 21% 21%;
  
  /* column-gap: 2vw; */
  justify-content: space-evenly;
  margin-top: 2vw;
  row-gap: 50px;
}
.profilePageImage {
cursor: pointer;
}
.profilePageImage:hover + .profilePageImagehover {
  display: flex;
}
.profilePageImage:hover .profilePageImagehover {
  display: flex;
}

.steps{
  background-color: #2141ce;
    padding-bottom: 70px;
    margin-top: 100px;
  }
  .steps_color{
    background-color: #2141ce;
    padding-bottom: 70px;
    margin-top: 100px;
  }
  .hirebtn:hover{
    background-color: rgb(255, 255, 255,0.9);
  
  }
  .hirebtn{
    background-color: transparent;
    
    margin: auto;
    transition: 200ms;
    text-align: center;
    height: 60px;
    
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .intro__component{
    display: flex;
    overflow: hidden;
    width: 95%;
    margin:120px auto;
     color: white;
    
    
    
    }

    .intro__component-mobile{
      display: flex;
      overflow: hidden;
      width: 95%;
      margin:40vw auto;
       color: white;
      
      
      
      }
      .step-mobile{
        min-width: 350px;
      
      }
      
    .intro__left--side-home{
    width: 70%;
  background-color: #2141ce;
    
    }
    .home_description-header{
      font-size: 20px;
    font-family: 'Young Serif', serif;
  
  
    }

    .modern-react-captcha__inputField{
      padding: 15px 8px;
    }
    .intro__right--side{
    width: 30%;
     background-color:white ;
    border: 4px solid #2141ce;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    }
    
    
    .intro__subheader{
    font-size: 35px;
    font-size: 900;
     padding-left: 20px ;
     padding-top: 20px;
     display: flex;
     color: white;
     text-transform: capitalize;
    }
    .intro__icon{
    
    filter: invert(1);
    width: 55px;
    margin-right: 15px;
    }
  
    
    .daw{
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: scale-down;
    
    
  
    }
    .intro__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    }
    .intro__para{
    
    width: 60%;
    padding-left: 95px;
    }
    .intro__para--last{
    padding-bottom: 25px;
    }
    
  
    .home__para{
      width: 98%;
      margin: auto;
      
      }
    
    .home__para-label{
      font-size: 30px;
      color: #4c00ff;
    }
    .grid-discover{
      display: grid;
           grid-template-columns: 33.33% 33.33% 33.33% ;
           /* column-gap: 30px; */
    
    }
    
      .home__para-header{
      text-align: center;
      width: 90%;
      margin: auto;
      font-size: 45px;
      text-transform: capitalize;
    
    }
    
    
      .home__para-para{
      width: 85%;
      margin: auto;
      margin-top: 20px;
      font-size: 15px;
      text-align: center;
    
      }
      .home__para-main-wrapper{
      display: flex;
      justify-content: space-evenly;
      /* display: grid;
      column-gap: 20px;
      justify-content: center;
         grid-template-columns: 200px 200px 200px 200px ; */
         margin-top: 90px;
      }
      .home__para-wrapper{
      width: 200px;
      
      
      }
.skillGrid{
  display: grid;
  grid-template-columns: auto  auto auto;
  column-gap: 5px;
  row-gap: 10px;
  justify-content: center;
  text-align: center; 
  word-break: keep-all;
  margin-top: 15px;
  align-items: center;

}
.borderCertificate{
  border-top: 2px solid black;
  width: 60%;
}

.form-control{
	width: 90%;
	overflow-y: hidden;
}
.skillClass{
    padding-top:2vw;
	padding-bottom:2vw;
	  }
.badScript{
  font-family: 'Bad Script', cursive;
}
.navDesktop{
  height: 100px;
  align-items: center;
}
.logo{
    font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 45px;
  color: #0b2149 ;


}

.anton{
  font:var(--podium-cds-typography-960-to-1919-display2);text-transform:uppercase;font-weight:500;display:undefined;
  font-size: 45px;


}
.loginButton{
  padding: 4px 10px;
}
#menuDesktop{
  left: 0;
  top: 0;
  width: 40%;
  height: 100vh;
}
#menuDesktop::-webkit-scrollbar {
	width: 15px;               /* width of the entire scrollbar */
  }
  #menuDesktop::-webkit-scrollbar-track {
    background: white;     /* color of the scroll thumb */
	      /* color of the tracking area */
  }
  .input-field-signUp:-webkit-autofill {
    caret-color: black;
    -webkit-box-shadow: 0 0 0 70px white inset; /* Override the autofill background color */
  }
  .input-field-signUp::placeholder {
    background-color: transparent; /* Set the background color of the placeholder text to transparent */
  }
  #menuDesktop::-webkit-scrollbar-thumb {
	
    background-color: rgba(119, 119, 119, 0.75);    /* color of the scroll thumb */

	/* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: none;  /* creates padding around scroll thumb */
  }
.borderBlue{
  border-left: 5px solid blue;
}
.cancelUploadDesktop{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

#loadThumbNail,#loadTrailer{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.courseVideo{
  width: 30%;
  height: 60vh;
}
.fjalla{
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;

  ;
  
}
.gridCourseSkill{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
}
.form-control-tab{
  font-size: 13px;
}

.Mooli{
	font-family: 'Mooli', sans-serif;
  font-size: 11px;

  
}.Indie{
  font-family: 'Indie Flower', "cursive";
}
#twelve{
  font-size: 12;
}

#submitDesktop{
	position: fixed;
	top: 0;
	left: 0;
	display:flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 400;
	background-color: #00ff59;
}
.nav_list{
  text-decoration: none;
	font-size: 17px;
	font-weight: 200;
	font-style: italic;
	text-transform: capitalize;
	font-family: 'Poppins', sans-serif;
  
}

.nav_list:hover{
  color: #2141ce;
}

.bordergray-800{
  
  border-bottom: 3px solid black;
}
#profileData{
	z-index: 2000;
	position: absolute;
	top: 60px;
	width: 200px;
	right: 20px;
}

#courseDataDesktop{
  position: absolute;
	top: 30px;
	width: 200px;
	right: 20px;
  z-index: 90;

}
.uploadText{
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 300ms;
  
  color: white;
}
.uploadText-wrapper:hover .uploadText{
  display: flex;
  scale: 1.1;


}
.borel{
	font-family: 'Mooli', sans-serif;
	font-weight: 400;
	font-size: 13px;
		}
.overflowX-scroll{
  overflow-x: scroll;
}
.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9/16 * 100) */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#spinnerDesktop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}
#stickynav{
  height: 100vh;
  position: fixed;
  top :0;
  left: 0;
  width: 5%;
  background-color: white;
  z-index: 100;

}
#menuDesktop{
  transition: 300ms ease-in-out;
}

#mainPage{
  height: 100%;
  position: absolute;
  top :0;
  left: 5%;
  width: 95%;
  background-color: white;
  z-index: 100;

}
#previewLoadingDesktop{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: none;
}
.bg-black{
  background-color: rgba(0, 0, 0,0.8);


}
.custom-shape-divider-top-1713118773 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1713118773 svg {
  position: relative;
  display: block;
  width: calc(208% + 1.3px);
  height: 379px;
  transform: rotateY(180deg);
}
courseGrid
.custom-shape-divider-top-1713134948 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: -1;
}

.custom-shape-divider-top-1713134948 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 96px;
}

.custom-shape-divider-top-1713134948 .shape-fill {
  fill: #2141ce;
}
.bg-black-main{
  background-color: rgba(0, 0, 0,0.8);


}
.studentName {
  font-size: clamp(4vw, 9vw, 80%);
}
.certificateDescription{
  font-size: clamp(1vw, 1.5vw, 80%);

}
.bebas{
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
}
.video-player-container {
  position: relative;
  width: 100%;
}


.video-player {
  width: 100%;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.backLinear{
  padding:'15px';

  min-height: 100vh;
  background-image: linear-gradient(#D8C7FF, #19006C);
  background-size: cover;
}

.ulChoice .correct{
  background:#dffff2;
  border-color:#00d397 ;
}
.ulChoice .incorrect{
  background:#ffebeb;
  border-color:#ff4a4a ;
}

.choiceDesktop{
  display: flex;
  align-items: center;
  height: auto;
  padding: 20px 5px;
  padding-left: 15px;
  border: 1px solid #68686868;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 13px;
  cursor: pointer;
}
.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
#editorCertificateDesktop{
  background: url('https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/565302e3-5bff-4e5c-8b76-a7f0246fcb4a_certificate-designs_backgrounds_11-08-22-2_26_09_Appreciation%20Professional%20Horizontal%20background.png?alt=media&token=e4191af9-fab3-4fa9-9abf-9b16bdbf738c');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;

}

#previewCertificateDesktop{
  background: url('https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/565302e3-5bff-4e5c-8b76-a7f0246fcb4a_certificate-designs_backgrounds_11-08-22-2_26_09_Appreciation%20Professional%20Horizontal%20background.png?alt=media&token=e4191af9-fab3-4fa9-9abf-9b16bdbf738c');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
}
.play-button-icon {
  width: 80px;
  height: 80px;
}
    #pricingPlanPop,#billingDateDesktop,#deleteLectureVideoDesktop,#deleteCertificateDesktop,#uploadCancelTrailerDesktop,#successQuizCreateDesktop,#deleteImgDesktop,#quizDesktopPop,#editPlanPop,#deleteAudioDesktop,#deletePdfDesktop,#addStudentDesktop,#successStudentDesktop,#uploadCancelDesktop,#deleteCourseDesktop,#uploadFileDesktop,.uploadFileDesktop{
      width: 100%;
      height: 100%;
      margin: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0,0.75);
    }
     
    .gridChoiceDesktop{
      display: grid;
      grid-template-columns: 45% 45%;
      column-gap: 30px;
      justify-content: center;
    }
    .choice-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      margin-right: 1rem;
    }
    .gridPreviewChoiceDesktop{
      display: grid;
      grid-template-columns: 45% 45%;
      column-gap: 30px;
      justify-content: center;
    }
    .homeGrid{
      display: grid;
      grid-template-columns: 31%  31% 31%;
      justify-content: space-evenly;

    }
.header{
  font-size: 20px;
	
	font-weight: 900;
	font-style: italic;
	text-transform: capitalize;
	font-family: 'Poppins', sans-serif;
}
#notification{
	z-index: 2000;
	position: absolute;
	top: 50px;
	width: 400px;
	right: 20px;
}

#reasonDesktop{
	z-index: 2000;
	position: absolute;
	top: 50px;
	width: 400px;
	right: 20px;
}
.width25{
  width:'20%'
}
.widthNone{
  width:0

}
.signature_invert{
  filter: invert();
}
.oswald {
  line-height: 50px;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  margin: 0;

  font-weight: 300;
  font-size: 59px;
 
  -webkit-text-decoration: none;
  text-decoration: none;
  font-style: normal;
  font-size: 72px;
  text-align: center;
  color: #000000ff;
  padding: 20px;
}
.css-eev3lq {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: white;
}
.css-1wej8zf {
  margin-top: 72px;
  padding: 0;
}
.css-ytebfg {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
}
.css-cuh8ez {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  padding: 80px 64px 64px;
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
.css-1x1u3e9 {
  max-width: 752px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  padding: 0;
  position: relative;
  z-index: 10;
}
.css-1l7xd1z {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.78px;
  text-transform: uppercase;
}

.css-1ekuqa3 {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-weight: 500;
  font-size: 56px;
  line-height: 105%;
  letter-spacing: -0.28px;
 
  text-align: center;
}
.css-oqthyc {
  max-width: 640px;
  width: 100%;
  text-align: center;
}
.css-79s2ig {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  font-feature-settings: "ss01" on;
  color: rgba(255, 255, 255, 0.85);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.09px;
  text-align: center;
}
.css-1lbfwu4 {
  width: 100%;
  padding: 48px 64px;
  background-color: #000000ff;
 


}

.css-194su0d {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 40px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.css-1nt8b0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.css-14704d4 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  height: 80px;
  width: 80px;
}
.css-14704d4 img {
  height:80px;
  width:80px;
}
.css-15vf48v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: 100%;
}
.css-15vf48v>h5 {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.css-c8mpmg {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  
  font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
  color: #000000ff;
  font-size: 44px;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -0.22px;
 
}

.css-1nr93 {
  background-color: rgba(255, 255, 255, 0.16);
  width: 100%;
  height: 1px;
}
.css-k705nv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  width: 100%;
}
.css-1c8o6vr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px rgba(27, 27, 27, 0.04), 0px 8px 16px 0px rgba(27, 27, 27, 0.04);
  background-color: #FFF;
  max-width: 636px;
  -webkit-flex-basis: 636px;
  -ms-flex-preferred-size: 636px;
  flex-basis: 636px;
  min-width: 20px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.css-vlx4w9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 32px 32px 32px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 32px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.16);
  position: relative;
}
.css-12y5ho8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 16px;
  border-radius: 12px 12px 0 0;
  
  background-color: #31ffaa;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.css-oxb5ly {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}
.css-u4eke3 {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  
  font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
  color: #000000ff;
  font-size: 28px;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: -0.14px;
 
}
.css-1yxzade {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  font-feature-settings: "ss01" on;
  color: rgba(27, 27, 27, 0.85);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.08px;
}
.css-1y4proi {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.css-1cf6g5n {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  gap: 8px;
}
.css-1js0cpk {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
  color: #000000ff;
  font-size: 44px;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -0.22px;
 
  font-weight: 600;
  font-family: '__BagossExtendedFont_6acf32', '__BagossExtendedFont_Fallback_6acf32', Arial, Helvetica, sans-serif;
}
.css-1yxzade {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  font-feature-settings: "ss01" on;
  color: rgba(27, 27, 27, 0.85);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.08px;
}
.css-fnszct {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.css-1uggw86 {
  font-feature-settings: 'ss02' on;
  font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
  text-align: center;
  text-transform: none;
  font-weight: 500;
  line-height: 170%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  -webkit-justify-content: normal;
  justify-content: normal;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  padding: 12px 16px;
  font-size: 16px;
  letter-spacing: 0.24px;
  height: 51px;
  border: none;
  border-radius: 200px;
  background-color: #000000ff;
  color: #FFFFFF;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
}
.css-gl4o9m {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  gap: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.css-1kvsmvo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 12px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.css-1x6lh4h {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  gap: 8px;
}
.css-xpxqog {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(27, 27, 27, 0.85);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
  
 
 

}
.css-18kphx1 {
  font-family: "Oswald", sans-serif;
  text-align: center;
  text-transform: none;
  font-weight: 300;
  line-height: 170%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-box-pack: normal;
  -ms-flex-pack: normal;
  -webkit-justify-content: normal;
  justify-content: normal;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  padding: 12px 16px;
  font-size: 16px;
  letter-spacing: 0.24px;
  height: 51px;
  border: none;
  border-radius: 200px;
  background-color: #000000ff;
  color: #FFFFFF;
}
.gatsby-image-wrapper{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  margin-top: 0;
  margin-bottom: 24px;
}
.btn-skin-1{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  box-sizing: border-box;
  touch-action: manipulation;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  font-family: SpaceMono,monospace,Courier New,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 600;
  padding: 16px 24px;
  transition: .3s ease-in-out;
  transition-property: all;
  font-size: 12px;
  line-height: 1.375;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  text-decoration: none;
  background-color: #1900bc;
  color: #fff;
}    
.columns-component{

  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  color: #54565a;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  background-color: #300a44;
  overflow: hidden;
  position: relative;
  perspective: 800px;
  max-width: 100%;
  display: inline-block;
  z-index: 9;
  perspective: 800px;
  width: 100%;
  height: 100%;
  position: relative;
  transform: perspective(800px);
  outline: 0;
}
.content-block{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  color: #54565a;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  background-color: white;
  margin-bottom: auto;
  padding: 16px;
  grid-column: span 4;
  padding-bottom: 80px;
}
.columns-wrapper{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  color: #54565a;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  grid-row-gap: 24px;
  display: grid;
  grid-auto-flow: row;
  padding: 4px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(12,1fr);
}
.columns-header{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  color: #54565a;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  margin-bottom: 24px;
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 24px;
  grid-template-columns: repeat(12,1fr);
}
.title2{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  text-align: center;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 400;
  font-size: 3.6428571429rem;
  line-height: 1.04;
  grid-column: 2/span 10;
  color: #000000;
}

.container{
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  text-rendering: optimizeSpeed;
  color: #54565a;
  font-family: Poppins,Helvetica Neue,helvetica,arial,sans-serif;
  font-weight: 300;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1220px;
  width: 100%;
  padding: 0 24px;
  position: relative;
  z-index: 2;
}
  .navigation-bar__container{
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    --font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
    --font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
    --font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
    --font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
    --font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
    --swiper-theme-color: #007aff;
    --sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
    color: #000000ff;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    font-family: var(--font-bagoss-condensed);
    box-sizing: border-box;
    transition: all .3s ease;
    z-index: 100;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    background-color: #fff;
 
  }css-ngu94h
  .css-1vpisfy{
    width: 30%;
  }
.logo{
font-family: 'anton';

font-size: 45px;
color: black ;


}

.navigation-bar__left-container{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
height: 100%;
display: flex;
align-items: center;
column-gap: 8px;
position: relative;
}
.navbar-item__container{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
height: 100%;
display: flex;
align-items: center;
}
.navbar-item__button{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
border: 1px solid transparent;
text-decoration: none;
height: 40px;
border-radius: 96px;
/* padding: 8px 6px!important; */
display: flex;
align-items: center;
column-gap: 8px;
cursor: pointer;
white-space: nowrap;
word-break: keep-all;
background-color: transparent;
font-weight: 500;
transition: all .3s ease;
font-style: normal;
line-height: 24px;
letter-spacing: .375px;

color: #000000ff;
}
.navigation-bar__right-container{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
display: flex;
align-items: center;
column-gap: 16px;
}
.MuiBox-root{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
overflow: hidden;
display: flex;
flex-direction: column;
-webkit-box-align: center;
align-items: center;
}
.css-1m2ucuw {
display: flex;
flex-direction: column;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
width: 50%;
}
.css-1nl1pyx {
display: flex;
flex-direction: column;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
gap: 16px;
}
.css-1nl1pyx img {
max-width: 600px;
}
.css-plijn5 {
box-sizing: border-box;
flex-direction: row;
margin: 16px 16px 200px;
border-radius: 8px;
max-width: 432px;
width: 100%;
min-height: 545px;
}
.login-form {
background-color: var(--white);
position: fixed;
right: 9%;
top: 50%;
transform: translateY(-50%);
}
.login-container-box-shadow {
box-shadow: 8px 8px 60px #00000040;
}
.css-1821gv5 {
display: flex;
flex-direction: column;
gap: 8px;
}
.css-wum4u3 {
margin: 0px;
font-size: 32px;
letter-spacing: -0.48px;
font-weight: 400;
line-height: 40px;
font-family: Bagoss, Arial, Helvetica, sans-serif;
}

.css-1d3bbye {
box-sizing: border-box;
display: flex;
flex-flow: wrap;
width: 100%;
}
.css-3kw8d2 {
margin-top: 24px;
}
.login-box {
max-width: 512px;
margin: auto;
}
.css-1bdosc3 {
padding: 6px 16px;
}
.css-1bdosc3 {
display: inline-flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
position: relative;
box-sizing: border-box;
-webkit-tap-highlight-color: transparent;
background-color: transparent;
outline: 0px;
margin: 0px;
cursor: pointer;
user-select: none;
vertical-align: middle;
appearance: none;
text-decoration: none;
letter-spacing: 0px;
font-weight: 500;
line-height: 1.75;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border: 1px solid rgb(27, 27, 27);
width: 100%;
box-shadow: none;
text-transform: initial;
color: rgb(27, 27, 27);
min-height: 40px;
min-width: 96px;
font-size: 14px;
border-radius: 10px;
padding: 6px 15px;
}
.logo[sizetype="24"] {
height: 24px;
}
.css-1e5u1e9 {
width: 20px;
height: 20px;
}
.login-form {
background-color: white;
position: fixed;
right: 9%;
top: 50%;
transform: translateY(-50%);
}
.login-logo-container {
position: fixed;
top: 0;
width: fit-content;
height: fit-content;
padding: 32px;
z-index: 1;
}
.css-plijn5 {
padding: 32px 24px;
}
.css-ki57r7 {
display: flex;
flex-direction: row;
position: fixed;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
z-index: -1;
}
.css-1821gv5 {
display: flex;
flex-direction: column;
gap: 8px;
}
.css-1e5u1e9 svg {
color: #FFFFFF;
fill:white;
}
.css-3zzrsz {
width: 100%;
display: grid;
grid-template-columns: repeat(7, 1fr);
row-gap: 24px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
justify-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
}
.css-8fe8oc {
width: 100%;
padding: 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 24px;
}
.css-v1hz9i {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 24px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
text-align: center;
}
.css-vxjk88 {
position: relative;
width: 100%;
height: 100%;
fill: #B1D8FC;
}
.css-16pdqkj {
width: 100%;
padding: 64px;

padding-bottom: 0;
}
.css-vp313s {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}

.mainBodyHomePage{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-ec60a877-a326-5783-6c70-ee5dac15822e';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
overflow: hidden;
display: flex;
flex-direction: column;
-webkit-box-align: center;
align-items: center;

}.css-1b04ki9 {
  height: 100%;
  width: 100%;
  position: relative;
}
.css-1xvmgvi {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 300;
font-size: 6rem;
line-height: 1.167;
text-wrap: balance;
-webkit-text-decoration: none;
text-decoration: none;
font-style: normal;
font-size: 72px;
font-weight: 500;
line-height: 105%;
letter-spacing: -0.72px;
width: 70%;
text-align: center;
color: #000000ff;
font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
padding: 20px;
margin: -5px;
}
.mainBodyHomePage-Btn:hover{
background-color: #343434;
}
.mainBodyHomePage-Btn {
font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
text-align: center;
font-weight: 500;
line-height: 170%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 4px;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
-webkit-box-pack: normal;
-ms-flex-pack: normal;
-webkit-justify-content: normal;
justify-content: normal;
-webkit-transition: all 0.1s ease-in-out;
transition: all 0.1s ease-in-out;
padding: 12px 16px;
font-size: 12px;
letter-spacing: 0.24px;
height: 51px;
border: none;
border-radius: 200px;
background-color: #000000ff;
color: #FFFFFF;
}
.css-1bjcx4s {

width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.css-a17wdw {
width: 100%;
padding: 48px 40px 96px;
}
.css-3lj5vg {
position: relative;
border-radius: 200px;
border: 2px solid rgba(27, 27, 27, 0.16);
height: 56px;
padding: 8px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-transition: -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
margin-top: 48px;
z-index: 0;
}
.css-e55qlo {
margin-top: 64px;
width: 100%;
}
.css-1b04ki9 {
height: 100%;
width: 100%;
position: relative;
}
.css-18w9lct {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;

}

.css-ducv57- {
height: auto;
width: 100%;


}
.css-13pmkot {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 40px;
}
.css-1h43wvm {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
max-width: 640px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
text-align: center;
}
.css-13fxktb {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
justify-content: center;
width:80%;
}
.css-dorob3 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
padding: 0px 32px;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
gap: 24px;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
border-left: 1px solid #C6BAB3;
}
.css-1i4ntia {
padding: 62px 0px 40px 0px;
background-color: #000000ff;
color: rgba(255, 255, 255, 100%);
}
.css-xf1wo8 {
max-width: 1224px !important;
margin: 0 auto;
padding-left: 32px !important;
padding-right: 32px !important;
width: 100% !important;
}
.css-djl52y {
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-flex-wrap: wrap;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
}.css-1em5jyh {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 48px;
}
.css-nrpsx8 {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.334;
font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-weight: 500;
font-size: 56px;
line-height: 105%;
letter-spacing: -0.28px;
text-wrap: balance;
}
.css-q8vqeo {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
color: rgba(27, 27, 27, 0.70);
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-size: 13px;
font-weight: 500;
line-height: 170%;
letter-spacing: 0.78px;
text-transform: uppercase;
}

.css-1pztp68 {
max-width: 863px;
width: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 20px;
text-align: center;
}
.css-ct33v1 {
width: 100%;
overflow: hidden;
padding: 80px 64px;
background-color: #000000ff;
}
.css-1oyb61h {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
max-width: 608px;
width: 100%;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
}
.css-13p1s5k {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;
font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-weight: 500;
font-size: 56px;
line-height: 105%;
letter-spacing: -0.28px;
text-wrap: balance;
}
.css-1si5xjn {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
flex-direction: row;
gap: 16px;
}
.css-ml5cmp {
width: 32px;
height: 32px;
background-color: #B1D8FC;
border-radius: 50%;
display: -webkit-inline-box;
display: -webkit-inline-flex;
display: -ms-inline-flexbox;
display: inline-flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;

color: #000000ff;
font-family: '__BagossExtendedFont_6acf32', '__BagossExtendedFont_Fallback_6acf32', Arial, Helvetica, sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 115%;
letter-spacing: -0.09px;
}
.css-1ok5qkj {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;

font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-size: 24px;
font-weight: 500;
line-height: 115%;
text-wrap: balance;
}
.css-1gnl65p {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0px 48px 0px 16px;
-webkit-align-self: stretch;
-ms-flex-item-align: stretch;
align-self: stretch;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.css-fxa9l1 {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
max-width: 640px;
}
.css-1gnl65p {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0px 48px 0px 16px;
-webkit-align-self: stretch;
-ms-flex-item-align: stretch;
align-self: stretch;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.css-1i2wr7p {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 20px 0px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
gap: 12px;
}
.css-fxa9l1 {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
max-width: 640px;
}
.css-41qk32 {
position: absolute;
right: 0;
top: 50%;
-webkit-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
border-radius: 16px;
background-color: #FFF;
min-width: 540px;
}
.css-3zg1cr {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-feature-settings: "ss01" on;
color: rgba(27, 27, 27, 0.85);
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-size: 14px;
font-weight: 400;
line-height: 160%;
letter-spacing: -0.09px;
text-align: left;
width: 100%;

}

.css-17u1xrw {
text-align: left;
width: 100%;
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;
font-feature-settings: ss02 on;
font-family: '__BagossExtendedFont_6acf32', '__BagossExtendedFont_Fallback_6acf32', Arial, Helvetica, sans-serif;
font-size: 96px;
font-weight: 600;
line-height: 100%;
letter-spacing: 0.48px;
margin-bottom: 4px;
}



.css-n8ap69 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 20px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
background-color: transparent;

align-items: center;
text-align: center;
}
.css-novx1h {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 500;
font-size: 1.25rem;
line-height: 1.6;
font-feature-settings: ss02 on;
font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
font-size: 15px;
font-weight: 700;
letter-spacing: 0.12px;
width: 100%;
text-align: left;
}
.css-wkfzqz {
width: 100%;
padding: 48px 64px 200px 64px;
background-color: #000000ff;
}

.css-1fllxtj {
position: relative;
width: 100%;
height: 100%;
margin-bottom: -2px;
fill: #000000ff;
}
.css-2fgig6 {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 72px;
}
.css-1vrckbq {
width: 100%;
max-width: 800px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 20px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
text-align: center;
}

element.style {
}
.css-1vrckbq p, .css-1vrckbq h5 {
  color: #FAF4EE;
}
.css-1d7ezcq {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
  color: #000000ff;
  font-weight: 500;
  font-size: 56px;
  line-height: 105%;
  letter-spacing: -0.28px;
 
  color: #FAF4EE ;
}
.css-10afrnc {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-feature-settings: "ss01" on;
color: rgba(27, 27, 27, 0.85);
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 160%;
letter-spacing: -0.09px;
color: rgba(250, 244, 238, 0.85) !important;
}
.css-wsfch7 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 16px;
}
.css-68zbsl
{
width: auto;
font-feature-settings: 'ss02' on;
font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
font-size: 13px;

} 
.css-1i4wc1c {
font-family: "Oswald", sans-serif;
text-align: center;
text-transform: none;
font-weight: 300;
line-height: 170%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 4px;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
-webkit-box-pack: normal;
-ms-flex-pack: normal;
-webkit-justify-content: normal;
justify-content: normal;
-webkit-transition: all 0.1s ease-in-out;
transition: all 0.1s ease-in-out;
padding: 12px 16px;
font-size: 16px;
letter-spacing: 0.24px;
height: 51px;
border: none;
border-radius: 200px;
background-color: #FFFFFF;
color: #000000ff;
border: 2px solid rgba(250, 244, 238, 0.16) !important;
}
.css-166nr2m {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
-webkit-box-flex: 1;
-webkit-flex-grow: 1;
-ms-flex-positive: 1;
flex-grow: 1;
-webkit-align-self: flex-start;
-ms-flex-item-align: flex-start;
align-self: flex-start;
}
.css-f2flyd {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
width: 100%;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
overflow: hidden;
border-radius: 12px;
}

.css-o4haub {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;
font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #FAF4EE;
background: url();
font-size: 24px;
font-weight: 500;
text-wrap: balance;
}
.css-19rso01 {
background-color: dark;
width: 100%;
padding: 64px;
background-color: #000000ff;
}
.css-135koaa {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 72px;
}
.css-aa5h2c {
margin: 0 auto;
width: 100%;
max-width: 667px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 24px;
}

.css-1d9c4bx {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 300;
font-size: 3.75rem;
line-height: 1.2;

font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-size: 44px;
font-weight: 500;
line-height: 105%;
letter-spacing: -0.22px;
text-wrap: balance;
text-align: center;
color: #FAF4EE;
}
.css-x8al29 {
display: flex;
justify-content: space-evenly;
width: 100%;
}
.css-fxuizj {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
padding: 0px 40px 0px 0px;
gap: 24px;
background-color: #000000ff;
border-radius: 12px;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
}
.css-16i37os {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
width: 112px;
height: 112px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: start;
-ms-flex-pack: start;
-webkit-justify-content: start;
justify-content: start;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
border-radius: 16px;
background-color: transparent;
}

.css-giiazi {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 500;
font-size: 1.25rem;
line-height: 1.6;

font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-size: 20px;
font-weight: 500;
line-height: 115%;
text-wrap: balance;
color: #FAF4EE;
}
.css-slho2y {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-feature-settings: "ss01" on;
color: rgba(27, 27, 27, 0.85);
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 170%;
letter-spacing: -0.08px;
color: #FAF4EE;
}

.css-1s5133g {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.css-1fllxtj {
position: relative;
width: 100%;
height: 100%;
margin-bottom: -2px;
fill: #000000ff;
}
.css-w4770a {
width: 100%;
padding: 64px;
background-color: #ffffff;
padding-bottom: 80px;
}
.css-g6fhwr {
width: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
padding: 48px 64px;
gap: 32px;
}
.css-qqilog {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 32px;
}
.css-1dk9sdk {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
text-align: center;
max-width: 800px;
width: 100%;
}

.css-9vbhak {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;
font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-weight: 500;
font-size: 56px;
line-height: 105%;
letter-spacing: -0.28px;
text-wrap: balance;
width: 100%;
text-align: center;
margin-bottom: 64px;
}
.css-hbu6zj {
overflow: hidden;
width: 100vw;
padding: 80px 64px 80px;
}
.css-8bp21p {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: grid;
row-gap: 48px;
grid-template-columns: 1fr auto;
grid-template-areas:
    "title nav"
    "carousel carousel"
    "scrollbar scrollbar";
}
.css-448w5t {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 300;
font-size: 3.75rem;
line-height: 1.2;

font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-size: 35px;
font-weight: 500;
line-height: 105%;
letter-spacing: -0.22px;
text-wrap: balance;
}

element.style {
}
.swiper-horizontal {
  touch-action: pan-y;
}
.css-1lsflv6 .swiper-scrollbar {
border-radius: 8px;
height: 4px;
background-color: rgba(27, 27, 27, 0.16);
}
.swiper-scrollbar {
border-radius: var(--swiper-scrollbar-border-radius, 10px);
position: relative;
touch-action: none;
background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, .1));
}
.css-1lsflv6 .swiper-scrollbar-drag {
background-color: rgba(27, 27, 27, 0.40);
cursor: pointer;
}
.swiper-scrollbar-drag {
height: 100%;
width: 100%;
position: relative;
background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, .5));
border-radius: var(--swiper-scrollbar-border-radius, 10px);
left: 0;
top: 0;
transform: translate3d(0px, 0px, 0px);
width: 200px;
transition-duration: 0ms;

}
.css-kjyy56 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: visible;
  padding: 0;
}


.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
position: absolute;
left: var(--swiper-scrollbar-sides-offset, 1%);
bottom: var(--swiper-scrollbar-bottom, 4px);
top: var(--swiper-scrollbar-top, auto);
z-index: 50;
height: var(--swiper-scrollbar-size, 4px);
width: calc(100% - 2* var(--swiper-scrollbar-sides-offset, 1%));
}
.css-1lsflv6 .swiper-scrollbar-drag {
background-color: rgba(27, 27, 27, 0.40);
cursor: pointer;
}
.css-kjyy56 {
position: relative;
top: 0;
left: 0;
width: 100%;
overflow: hidden;
scrollbar-width: none;
grid-area: carousel;
overflow: visible;
padding: 0;
}
.swiper-wrapper {
position: relative;
width: 100%;
height: 100%;
z-index: 1;
display: flex;
transition-property: transform;
transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
box-sizing: content-box;
transition-duration: 0ms;
transform: translate3d(0px, 0px, 0px);
transition-delay: 0ms;

}
.css-16cltnk {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
gap: 32px;
-webkit-align-self: stretch;
-ms-flex-item-align: stretch;
align-self: stretch;
-webkit-box-flex: 1;
-webkit-flex-grow: 1;
-ms-flex-positive: 1;
flex-grow: 1;
}
.css-a6gpvu {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
font-weight: 400;
font-style: normal;
font-size: 22px;
line-height: 140%;
letter-spacing: -0.22px;
color: #000000ff;
}
.css-l7pcke {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 24px;
-webkit-align-self: stretch;
-ms-flex-item-align: stretch;
align-self: stretch;
}
.css-zg4nl1 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0px;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 13px;
-webkit-flex: 1 0 0;
-ms-flex: 1 0 0;
flex: 1 0 0;
}
.css-wowz6z {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-self: center;
-ms-flex-item-align: center;
align-self: center;
}

.css-1jcaegn {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 32px 32px 24px 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
border-radius: 12px;
background: #C4B1F9;
}
.css-19orgr{
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 32px 32px 24px 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
border-radius: 12px;
background: #C7E2FE;
}
.css-19orgr2{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 32px 24px 32px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-radius: 12px;
  background: #67fbd8;
  }
.css-zpwqyx {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 32px 32px 24px 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
border-radius: 12px;
background: #FFE27C;
}
.css-382l81 {

display: -webkit-box !important;
display: -webkit-flex !important;
display: -ms-flexbox !important;
display: flex !important;
}
.css-1w59bsm {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
gap: 12px;
grid-area: nav;
justify-self: end;
}

.css-1mrtzu3:disabled {
cursor: default;
background-color: rgb(27 27 27 / 10%);
}
.css-1mrtzu3 {
font-feature-settings: 'ss02' on;
font-family: '__BagossStandardFont_073d4e', '__BagossStandardFont_Fallback_073d4e', Arial, Helvetica, sans-serif;
text-align: center;
text-transform: none;
font-weight: 500;
line-height: 170%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 4px;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-transition: all 0.1s ease-in-out;
transition: all 0.1s ease-in-out;
padding: 0;
font-size: 16px;
letter-spacing: 0.24px;
height: 51px;
border: none;
border-radius: 50%;
background-color: #000000ff;
color: #FFFFFF;
width: 48px;
height: 48px;
}
.css-vubbuv {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
width: 1em;
height: 1em;
display: inline-block;
fill: currentColor;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
font-size: 1.5rem;
color: #FFFFFF;

}
.css-1mrtzu3 svg {
}
.css-106mqxj {
position: relative;
grid-area: title;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-right: 20px;
}
.css-wo8mfg {
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
max-width: 800px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 16px;
}
.css-exnllr{
max-width: 80%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 48px;
padding-top: 24px;
border-top: 1px solid rgba(27, 27, 27, 0.16);
}
.css-fa5zzh {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;

font-family: '__BagossCondensedFont_6a34bf', '__BagossCondensedFont_Fallback_6a34bf', Arial, Helvetica, sans-serif;
color: #000000ff;
font-size: 24px;
font-weight: 500;
line-height: 115%;
text-wrap: balance;
}
.css-1c26xg0 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex !important;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
gap: 32px;
-webkit-align-content: flex-start;
-ms-flex-line-pack: flex-start;
align-content: flex-start;
-webkit-align-items: stretch;
-webkit-box-align: stretch;
-ms-flex-align: stretch;
align-items: stretch;
}
.css-6rpn08 {
width: 100%;
height: 100%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
width: auto;
height: 100%;
max-height: 120px;
}
.css-ducv57 {
width: 100%;
height: auto;
color: transparent;
}
.css-12vj066 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-align-items: flex-start;
-webkit-box-align: flex-start;
-ms-flex-align: flex-start;
align-items: flex-start;
}
.css-68zxer {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 1.5rem;
line-height: 1.334;

font-family: '__BagossExtendedFont_6acf32', '__BagossExtendedFont_Fallback_6acf32', Arial, Helvetica, sans-serif;
color: #000000ff;
font-weight: 600;
font-size: 80px;
line-height: 100%;
letter-spacing: -0.4px;
font-style: normal;
text-wrap: balance;
}
.css-1qg1cbt {
margin: 0;
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-feature-settings: "ss01" on;
color: rgba(27, 27, 27, 0.85);
font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 170%;
letter-spacing: -0.08px;
}
.css-l9c3d3 {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 12px;
}
.css-in2h3y {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
gap: 20px;
color: #FAF4EE;
}
.css-ngu94h {
  font-family: "Oswald", sans-serif;
text-align: center;
text-transform: none;
font-weight: 300;
line-height: 170%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
gap: 4px;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
-webkit-box-pack: normal;
-ms-flex-pack: normal;
-webkit-justify-content: normal;
justify-content: normal;
-webkit-transition: all 0.1s ease-in-out;
transition: all 0.1s ease-in-out;
padding: 12px 16px;
font-size: 16px;
letter-spacing: 0.24px;
height: 51px;
border: none;
border-radius: 200px;
background-color: #000000ff;
color: #FFFFFF;
color: #FFFFFF;
border: 2px solid rgba(250, 244, 238, 0.16);
}
.css-in2h3y {
width: 100%;
color: #FAF4EE;
font-size: 18px;
text-align: center;
}
.css-v3wz6g {
  margin: 0;
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(27, 27, 27, 0.70);
  font-family: '__Inter_aaf875', '__Inter_Fallback_aaf875', Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.78px;
  text-transform: uppercase;
  color: rgba(250, 244, 238, 0.75);
}
.css-1cjibqg {
width: 100%;
display: flex;
 justify-content: space-evenly;
 justify-content: space-evenly;
 width: 100%;
}
.css-vp313s {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.css-vp313s {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: flex;
gap: 32px;
flex-direction: column;
align-items: center;
background-color: transparent;
}
.css-16pdqkj {
width: 100%;
padding: 64px;

padding-bottom: 0;
}
.navbar-item__button-default,.navbar-item__chevron {
border-radius: 200px;
color: #000000ff;
font-family: var(--font-bagoss-condensed);
font-weight: 100;

outline: none;
}

.navbar-item__button-default:hover{
color: #007aff;
}
.border-button-black{
border-radius: 200px;
border: 2px solid rgba(27, 27, 27, .16);
color: white;
background-color: black;
font-family: var(--font-bagoss-condensed);
font-size: 15px;
font-weight: 500;

outline: none;
}
.css-1jrf6a4
{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-ec60a877-a326-5783-6c70-ee5dac15822e';
box-sizing: inherit;
text-wrap: balance;
text-decoration: none;
font-style: normal;
font-size: 72px;
font-weight: 500;
letter-spacing: -0.78px;

text-align: center;
color: #000000ff;
font-family: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
margin: -5px;
background-repeat: no-repeat;
background-position: left center,center,right;
padding-left: 20px;
padding-right: 20px;
animation: slide-out 0.5s ease;
animation-fill-mode: forwards;
}
.border-button{
border-radius: 200px;
border: 2px solid rgba(27, 27, 27, .16);
color: #000000ff;
font-family: var(--font-bagoss-condensed);
font-size: 15px;
font-weight: 500;

outline: none;
}
.navbar-item__button-text{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-family: "Oswald", sans-serif;
white-space: nowrap;
word-break: keep-all;
font-style: normal;
line-height: 24px;
letter-spacing: .375px;
cursor: pointer;
font-size: 11px;
font-weight: 500;

color: #000000ff;
vertical-align: middle;
font-family: var(--font-bagoss-condensed);
box-sizing: border-box;

}
.css-vp313s {
max-width: 100%;
width: 100%;
margin: 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
gap: 32px;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
}
.MuiBox-root{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-ec60a877-a326-5783-6c70-ee5dac15822e';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
box-sizing: inherit;
font-family: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
width: 100%;
flex-direction: column;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
padding: 32px 0px;
gap: 16px;
}

.navigation-bar__container{
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
--font-inter: '__Inter_aaf875','__Inter_Fallback_aaf875',Arial,Helvetica,sans-serif;
--font-bagoss-condensed: '__BagossCondensedFont_6a34bf','__BagossCondensedFont_Fallback_6a34bf',Arial,Helvetica,sans-serif;
--font-bagoss-standard: '__BagossStandardFont_073d4e','__BagossStandardFont_Fallback_073d4e',Arial,Helvetica,sans-serif;
--font-bagoss-extended: '__BagossExtendedFont_6acf32','__BagossExtendedFont_Fallback_6acf32',Arial,Helvetica,sans-serif;
--font-bagoss-standard-vf: '__BagossStandardVF_32f2e9','__BagossStandardVF_Fallback_32f2e9',Arial,Helvetica,sans-serif;
--swiper-theme-color: #007aff;
--sa-uid: '0-68cf66a5-cc0d-5c3e-4449-af13e221bfd7';
color: #000000ff;
font-weight: 400;
font-size: 12px;
line-height: 1.5;
font-family: var(--font-bagoss-condensed);
box-sizing: border-box;
transition: all .3s ease;
z-index: 100;
height: 72px;

display: flex;
align-items: center;
justify-content: space-between;
padding: 0 48px;
background-color: #fff;

}    

@media (min-width: 0px) and (max-width: 550px) {
  #desktop,#tablet{
    display: none;
  }
  
.css-n8ap69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background-color: transparent;
  
  align-items: center;
  text-align: center;
  }
  .oswald {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    color: #000000ff;
    padding: 20px;
    line-height: 14.5vw;

  }
 
  .paymentMethod{
    width: 30%;
    
  }
  .ulChoice .correct{
    background:#dffff2;
    border-color:#00d397 ;
  }
  .ulChoice .incorrect{
    background:#ffebeb;
    border-color:#ff4a4a ;
  }
  
  .choiceDesktop{
    display: flex;
    align-items: center;
    padding-left: 5vw;
    border: 1px solid #68686868;
    border-radius: 2vw;
    margin-bottom: 5vw;
    font-size: 3vw;
    cursor: pointer;
  }
  #reasonDesktop{
    z-index: 2000;
    position: absolute;
    top: 50px;
    width: 50vw;
    right: 0px;
  }
  .gridChoiceDesktop{
    display: grid;
    grid-template-columns: 100% ;
    justify-content: center;
  }
  .mobileGridCourses{
    display: grid;
    grid-template-columns: 48% 48% ;
  
    row-gap: 5vw;
    column-gap: 2vw;

    justify-content: space-evenly;
    margin-top: 2vw;
  }
  .form-control{
    height: 14vw;
    padding-left: 5vw;
  }

  .tagClass{
    height: 14vw;
    font-size: 3vw;
    padding-left: 5vw;
  }
  .img-support{
    
    position: relative;
    z-index: -30;
    margin-bottom: -26px;
  }
#submitMobile{
  position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 400;
	background-color: #00ff59;
}
  .logo{
    font-size: 7vw;
    padding-left: 4vw;
  }
  .navMobile{
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    align-items: center;
    /* background-color: #f9fcf7; */

  }
  
  #popoutMobile,#popoutMobileHome{
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom:  0;
    left: 0;
    z-index: 100;
    background-color: white;
  }
  .listPopoutTablet{
    padding-top: 5vw;
padding-left: 4vw;
    padding-bottom: 5vw;
  font-family: 'Indie Flower', "cursive";
  color: #0b2149;
  font-size: 3.5vw;

  }
  .navBottomBTN{
    position: absolute;
    bottom: 5%;
    left: 50%;
    width: 40%;
    height: auto;
    transform: translate(-50%);
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 3.5vw;
  

  }

}

@media (min-width: 551px) and (max-width: 900px) {
  #desktop,#mobile{
    display: none;
  }
  .css-1c8o6vrr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(27, 27, 27, 0.04), 0px 8px 16px 0px rgba(27, 27, 27, 0.04);
    background-color: #FFF;
    max-width: 636px;
    -webkit-flex-basis: 636px;
    -ms-flex-preferred-size: 636px;
    flex-basis: 636px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  
  #notification{
    z-index: 2000;
    position: absolute;
    top: 50px;
    width: 50vw;
    right: 0px;
  }
  
  .courseGrid{
    display: grid;
    grid-template-columns: 30% 30% 30% ;
  
    /* column-gap: 2vw; */
    justify-content: space-evenly;
    margin-top: 2vw;
    row-gap: 5vw;
  }
#menuTablet{
  width: 100%;
}

#lectureTablet{
  width: 100%;

}
  #previewCertificateDesktop {
    background-size: (contain,100%);
    height: 100vh;
}
  .home_description{
    width: 50%;

  }
  
  #submitTablet{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 400;
    background-color: #00ff59;
  }
  .home_img{
    width: 60%;
  }
  .home_description-header{
    font-size: 3vw;
  font-family: 'Young Serif', serif;


  }
  .young{
    font-family: 'Young Serif', serif;
  
    
  }
  css-vlx4w9
  .Mooli{
    font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
    
  }

  .border-25{
    border-width: 3px ;
  }
  .home_description-para{
    font-size: 2vw;

  }
  .homePageButton{
    padding-top: 1.3vw;
    padding-bottom: 1.3vw;
    /* padding-left: 5vw;
    padding-right:5vw; */
    font-size: 2vw;

  }
  
  .navTablet{
    padding-left: 10px;
    padding-right: 10px;
align-items: center;

/* background-color: #f9fcf7; */
 
  }
anton
  #popoutTablet,#popoutTabletHome{
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom:  0;
    left: 0;
    z-index: 100;
    background-color: white;

  }
  .listPopoutTablet{
    padding-top: 10px;
padding-left: 10px;
    padding-bottom: 2vw;
  font-family: 'Indie Flower', "cursive";
  color: #0b2149;
  font-size: 18px;

  }

  .navBottomBTN{
    position: absolute;
    bottom: 5%;
    left: 50%;
    width: 40%;
    height: auto;
    transform: translate(-50%);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  

  }
  .menuIcon{
    width: 5vw;
    margin-left: 3vw
  }
  .logo{
    padding: 0;
    font-size: 6vw;
    padding-left: 10px;
  }
  .nav_list{
    font-size: 2.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  #courseObjectiveDesktop,#courseDescriptionDesktop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0,0.6);
    z-index: 100;
  }
 
  #successCourseAddDesktop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0,0.75);
    z-index: 100;

  }
}


@media (min-width: 901px) {
  #mobile,#tablet{
    display: none;
  }



  
#desktopLoader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;

}

.poppins{
  font-size: 1.5vw;
  font-family: 'Young Serif', serif;
}
.Mooli{
	font-family: 'Mooli', sans-serif;

  
}
.loader {
  width: 100px;
  aspect-ratio: 1;
  color: #9509FF;
  background: conic-gradient(currentColor 0 270deg,#0000 0);
  border-radius: 50%;
  animation: l14-0 4s infinite linear;
}

.loader2 {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader2:before {
  content:"Loading..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}

.loader::before {
  content: "";
  display: block;
  height: 50%;
  width: 50%;
  border-top-left-radius: 100px;
  background: currentColor;
  animation: l14 0.5s infinite alternate;
}
.howItWork_main .container {
 width:100%;
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
}
 .howItWork_main .demo_cta {
  width: 100%;
  max-width: 117px;
  height: 51px;
  display: inline-block;
  border-radius: var(--circular, 200px);
  background: var(--Surface-Dark, #000000ff);
  color: var(--Content-White, #FFF);
  text-align: center;
  font-family: '__BagossStandardVF_32f2e9';
  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.24px;
  padding: 12px 0 0;
  margin-top: 48px;
}
.howItWork_main h2 {
  color: #000000ff;
  font-family: "Bagoss Condensed";
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -0.28px;
  margin: 0 0 47px;
}
.howItWork_main .iframe_main {
  border-radius: 36px;
  border: 2px solid var(--Border-Primary, rgba(27, 27, 27, 0.16));
  padding: 13px;
  display: flex;
}
 .howItWork_main .container iframe {
  height: 758px !important;
  border-radius: var(--xxs, 26px);
  border: 9px solid var(--Surface-Dark-Subtle, #343434) !important;
  box-shadow: 0px 20px 32px 0px rgba(27, 27, 27, 0.20), 0px 4px 40px 0px rgba(27, 27, 27, 0.13);
}
.howItWork_main {
  width: 100%;
  padding: 79px 0 0;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/kesht-academy.appspot.com/o/asterina%20(47).png?alt=media&token=197a33b2-3c11-4e28-96af-a4d4902f7c67');
  background-size: 1500px;
  background-position: 49% 83.5%;
  background-repeat: no-repeat;
  object-fit: contain;
}
@keyframes l14-0 {
    0%,24.99%  {transform: rotate(0deg)}
    25%,49.99% {transform: rotate(90deg)}
    50%,74.99% {transform: rotate(180deg)}
    75%,100%   {transform: rotate(270deg)}
}
@keyframes l14 {
    100%  {transform: translate(-10px,-10px)}
}
  #courseObjectiveDesktop,#courseDescriptionDesktop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0,0.6);
    z-index: 100;
  }
  .home_description{
    width: 50%;

  }

  .nav__list{
    display: flex;
    list-style: none;
    }
    
    .nav__list-link{
    margin: 0 8px;
    text-decoration: none;
    text-transform: capitalize;
    
    }
  .nav__list--anchor{
    text-decoration: none;
    color: black;
    font-weight: 200;
    font-style: italic;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    }
    .nav__list--anchor:hover{
    color: #2141ce;
    }
  #loadingDesktop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    flex-direction: column;
    height: 100%;
    background-color: white;
    z-index: 100;
    justify-content: center;
    align-items: center;
  }

  .mobile-flex-steps-mid::-webkit-scrollbar {
    width: 15px;               /* width of the entire scrollbar */
    }
    .mobile-flex-steps-mid::-webkit-scrollbar-track {
    background-color: #2141ce;    /* color of the scroll thumb */
          /* color of the tracking area */
    }
    
    .mobile-flex-steps-mid::-webkit-scrollbar-thumb {
    background: white;  
    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: none;  /* creates padding around scroll thumb */
    }
  #successCourseAddDesktop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0,0.75);
    z-index: 100;

  }

  .videoCourseWrapper{
    width: 90%;
    height: 30vw
    
  }
  .home_img{
    width: 80%;
  }
  .home_description-header{
    font-size: 2.5vw;
  font-family: 'Young Serif', serif;

  }
}
.home-video1 {
  color: white;
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
.home-stats {
  width: 100%;
  display: flex;
  z-index: 10;
  max-width: 1200px;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-caption4 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-description1 {
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-caption5 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
.home-description2 {
  width: 100%;
  font-size: 18px;
  max-width: 350px;
  text-align: center;
  font-family: Poppins;
  line-height: 27px;
}
.home-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  border-color: #DBDBDB;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-left-width: 1px;
  border-bottom-width: 1px;
}
.home-caption6 {
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 56px;
}
home-video1 {
  color: white;
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
.home-content12 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
}
.home-header1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text10 {
  width: 640px;
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-video-container {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: flex-start;
  flex-direction: column;
}
.home-video2 {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;
  border-color: rgba(0, 0, 0, 0);
  border-width: 2px;
}
.home-video2:hover {
  opacity: .5;
  border-color: #ADE2DF;
  border-width: 2px;
  border-radius: 8px;
}
.home-heading-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text11 {
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.home-text12 {
  color: #EAEAEA;
  width: 600px;
  font-family: Poppins;
  line-height: 24px;
}
.home-explore1 {
  color: #fff;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 0px;
  flex-direction: column;
  padding-bottom: 4px;
  border-bottom-width: 1px;
}
.input-field-signUp{
  border: 0.5px solid black;
}